export const images = [
    { id: 1, src: '../img/smestaj/soba1.jpg', alt: 'Image 1' },
    { id: 2, src: '../img/smestaj/soba2.jpg', alt: 'Image 1' },
    { id: 3, src: '../img/smestaj/soba3.jpg', alt: 'Image 1' },
    { id: 4, src: '../img/smestaj/soba4.jpg', alt: 'Image 1' },
    { id: 5, src: '../img/smestaj/soba5.jpg', alt: 'Image 1' },
    { id: 6, src: '../img/smestaj/soba6.jpg', alt: 'Image 1' },
    { id: 7, src: '../img/smestaj/soba7.jpg', alt: 'Image 1' },
    { id: 8, src: '../img/smestaj/soba8.jpg', alt: 'Image 1' },
    { id: 8, src: '../img/smestaj/soba9.jpg', alt: 'Image 1' },
    { id: 8, src: '../img/smestaj/soba10.jpg', alt: 'Image 1' },
    { id: 8, src: '../img/smestaj/soba11.jpg', alt: 'Image 1' },
    { id: 8, src: '../img/smestaj/soba12.jpg', alt: 'Image 1' },
    { id: 8, src: '../img/smestaj/terasa1.jpg', alt: 'Image 1' },
    { id: 8, src: '../img/smestaj/terasa2.jpg', alt: 'Image 1' },
    { id: 8, src: '../img/smestaj/terasa3.jpg', alt: 'Image 1' },
    { id: 8, src: '../img/smestaj/trpezarija1.jpg', alt: 'Image 1' },
    { id: 8, src: '../img/smestaj/trpezarija2.jpg', alt: 'Image 1' },
    { id: 8, src: '../img/smestaj/trpezarija3.jpg', alt: 'Image 1' },
    { id: 8, src: '../img/smestaj/trpezarija4.jpg', alt: 'Image 1' },
    { id: 8, src: '../img/smestaj/parking1.jpg', alt: 'Image 1' },
    { id: 8, src: '../img/smestaj/kuhinja1.jpg', alt: 'Image 1' },
    { id: 8, src: '../img/smestaj/kuhinja2.jpg', alt: 'Image 1' },
    { id: 8, src: '../img/smestaj/kupatilo.jpg', alt: 'Image 1' },
    { id: 8, src: '../img/smestaj/kupatilo1.jpg', alt: 'Image 1' },
    { id: 8, src: '../img/smestaj/kupatilo2.jpg', alt: 'Image 1' },
    { id: 8, src: '../img/smestaj/kupatilo3.jpg', alt: 'Image 1' },
  ];